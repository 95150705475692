import React, { useEffect, useState } from "react";
import { Button, Checkbox, Drawer, Input, Tag } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { trackingDeliveriesSelector } from "../../../../../../../features/senninha/trackingDeliveriesSlice";
import machineError from "@assets/imgs/machine-error.svg";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";
import { getTickets, resetTicketSlice, ticketsSelector } from "../../../../../../../features/senninha/ticketSlice";

import * as S from "./styles";


const ProgressContent = ({ onOpenDeliveryDrawer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState()
    const [activeFilters, setActiveFilters] = useState({
        opl: null,
        batch: null,
        searchBar: null,
    })
    const [searchText, setSearchText] = useState('')
    const [filteredDeliveries, setFilteredDeliveries] = useState([])
    const [checkedList, setCheckedList] = useState([])
    const [checkboxOptions, setCheckboxOptions] = useState([])

    const dispatch = useDispatch();

    const { 
        deliveries, 
        statusGetDeliveries, 
        errorGetDeliveries
    } = useSelector(trackingDeliveriesSelector)

    const { status: statusTickets } = useSelector(ticketsSelector)

    const loading = statusGetDeliveries === 'pending'
    const checkAll = checkboxOptions.length === checkedList.length && checkedList.length
    const indeterminate = checkedList.length > 0 && checkedList.length < checkboxOptions.length

    const togglePopover = () => setIsOpen((p) => !p);

    const getAllFilters = () => {
        const opls = []
        const batches = []
        deliveries.forEach(({ operador_logistico, lote }) => {
            if (!opls.find((item) => item === operador_logistico))
                opls.push(operador_logistico)
            if (!batches.find((item) => item === lote))
                batches.push(lote)
        })
        return { opls, batches }
    }

    const applyFilters = () => {
        const res = deliveries?.filter(({ lote, operador_logistico, id_atendimento, oss }) => 
            (activeFilters.opl ? activeFilters.opl === operador_logistico : true) 
            && (activeFilters.batch ? activeFilters.batch === lote : true)
            && (activeFilters.searchBar ? 
                ((id_atendimento.includes(activeFilters.searchBar))
                || (oss.find(({ id_os }) => id_os.toString().includes(activeFilters.searchBar))))
                : true)
        )
        if (res) setFilteredDeliveries(res)
        return res
    }

    const onCheckAllChange = (e) =>
        setCheckedList(e.target.checked ? checkboxOptions : [])   

    const handleChangeCheckbox = (checkedValue) =>
        setCheckedList((p) => checkedList.find((item) => item === checkedValue) ? 
            p.filter((item) => item !== checkedValue)
            : [...p, checkedValue]
        )

    const handlePrint = () => {
        const deliveries2BePrinted = deliveries.filter(({ reference_key }) => checkedList.includes(reference_key))
        const payload = deliveries2BePrinted.map(({ id_atendimento, reference_key }) => ({
            id_atendimento,
            reference_key
        }))

        dispatch(getTickets(payload))
    }

    const content = () => (
        filters &&
            <>
                <S.HeaderPopover>
                    <span>Filtrar Por</span>
                    <GingaIcon name='close' color="rgba(48, 55, 66, 1)" size={24} onClick={togglePopover} />
                </S.HeaderPopover>
                <S.SubTitlesPopover>
                    <p>Operador logístico</p>
                    <S.Tags>
                        {filters.opls.map((item) => (
                            <S.TagCustom
                                onClick={() => setActiveFilters(
                                    (p) => ({ 
                                        ...p, 
                                        opl: activeFilters.opl === item ? null : item 
                                    })
                                )}
                                isActive={activeFilters.opl === item}
                            >
                                {capitalizeAllFirstLetters(item)}
                            </S.TagCustom>
                        ))}
                    </S.Tags>
                </S.SubTitlesPopover>
                <S.SubTitlesPopover>
                    <p>Lote</p>
                    <S.Tags>
                        {filters.batches.map((item) => (
                            <S.TagCustom
                                onClick={() => setActiveFilters(
                                    (p) => ({ 
                                        ...p, 
                                        batch: activeFilters.batch === item ? null : item 
                                    })
                                )}
                                isActive={activeFilters.batch === item}
                            >
                                {item}
                            </S.TagCustom>
                        ))}
                    </S.Tags>
                </S.SubTitlesPopover>
                <S.FooterButton>
                    <Button
                        type="primary"
                        onClick={() => {
                            togglePopover()
                            const res = applyFilters()
                            if ((activeFilters.opl || activeFilters.batch) && res?.length) {
                                const allSimulationsIds = res.map((item) => item.reference_key)
                                setCheckedList(checkedList.filter((item) => allSimulationsIds.includes(item)))
                            }
                        }}
                        width="100%"
                    >
                        Filtrar
                    </Button>
                </S.FooterButton>
            </>
    );

    useEffect(() => {
        if (deliveries) setFilters(getAllFilters())
    }, [deliveries])

    useEffect(() => {
        if (!isOpen && !activeFilters.opl && !activeFilters.batch)
            setFilteredDeliveries(deliveries)            
    }, [isOpen, deliveries])

    useEffect(() => {
        if (!searchText) {
            setActiveFilters((p) => ({ ...p, searchBar: null }))
        }
    }, [searchText])

    useEffect(() => {
        if (filteredDeliveries?.length) 
            setCheckboxOptions(filteredDeliveries.map(({ reference_key }) => reference_key))
    }, [filteredDeliveries])

    useEffect(() => {
        const res = applyFilters()
        if (activeFilters.searchBar && res?.length) {
            const allSimulationsIds = res.map((item) => item.reference_key)
            setCheckedList(checkedList.filter((item) => allSimulationsIds.includes(item)))
        }
    }, [activeFilters.searchBar])

    useEffect(() => {
        if (statusTickets === 'fulfilled') {
            setCheckedList([])
            dispatch(resetTicketSlice())
        }
    }, [statusTickets])

    return (
        <Drawer.Body>
            {loading ? 
                <Drawer.Loading
                    message="Carregando deliveries..."
                />
                :
                errorGetDeliveries ?
                    <Drawer.Response
                        element={ <img src={machineError} />}
                        message="Houve um erro!"
                    />
                    :
                    deliveries?.length ?
                        <>
                            <S.AllDeliveryDrawerContent>
                                <S.HeaderDelivery>
                                    <Input
                                        label='Pesquisar'
                                        placeholder='Stonecode ou Nº da OS'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onPressEnter={(e) => {
                                            setActiveFilters((p) => ({ ...p, searchBar: e.target.value }))
                                        }}
                                        size="small"
                                        suffixIcon={<GingaIcon name='search' color='#767F8D' size='18px' />}
                                        allowClear
                                    />
                                    <Popover
                                        open={isOpen}
                                        placement="bottomRight"
                                        trigger="click"
                                        content={content}
                                        overlayClassName="ginga-popover"
                                    >
                                        <Button
                                            type="primary"
                                            width="44px"
                                            height="44px"
                                            onClick={togglePopover}
                                        >
                                            <GingaIcon
                                                name="filter"
                                                color="#fff"
                                            />
                                        </Button>
                                    </Popover>
                                </S.HeaderDelivery>
                                <S.DeliveryTopContent>
                                    <Checkbox
                                        label={`Selecionar todos`}
                                        indeterminate={indeterminate}
                                        onChange={onCheckAllChange}
                                        checked={checkAll}
                                        size={24}
                                    />
                                    <Button
                                        type='primary'
                                        height='36px'
                                        onClick={handlePrint}
                                        loading={statusTickets === 'pending'}
                                        disabled={!checkedList.length}
                                    >
                                        <GingaIcon
                                            name="printer"
                                            color="#fff"
                                            size={16}
                                        />
                                        Imprimir etiqueta ({checkedList.length})
                                    </Button>
                                </S.DeliveryTopContent>

                            </S.AllDeliveryDrawerContent>

                                {filteredDeliveries?.map((delivery, i) => (
                                        <Drawer.Row
                                            key={i}
                                            onClick={() => onOpenDeliveryDrawer(delivery)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Drawer.RowPrefix>
                                                <Checkbox
                                                    size={24}
                                                    value={delivery.reference_key}
                                                    checked={checkedList.includes(delivery.reference_key)}
                                                    onChange={(e) => handleChangeCheckbox(e.target.value)}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                    }}
                                                />
                                            </Drawer.RowPrefix>
                                            <Drawer.RowText
                                                text={capitalizeAllFirstLetters(delivery.cliente)}
                                                description={`${
                                                    capitalizeAllFirstLetters(delivery.operador_logistico)
                                                } | ${
                                                    delivery.oss.map(({ servico, id_os }) => 
                                                    ` ${capitalizeAllFirstLetters(servico)} (OS ${id_os})`)
                                                } | Lote ${delivery.lote}`}
                                            />
                
                                            <Drawer.RowActions>
                                                <Tag
                                                    text={capitalizeAllFirstLetters(delivery.status.mensagem)}
                                                    type={delivery.status.tipo}
                                                />
                                            </Drawer.RowActions>
                                        </Drawer.Row>
                                ))}
                        </>
                    :
                    <Drawer.Empty />
            }
        </Drawer.Body>
    )
}

export default ProgressContent;
