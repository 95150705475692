import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';
import { downloadBase64PDF } from '../../utils/common'

const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'ticketPreDelivery';
const GET_TICKETS = 'ticketPreDelivery/getTickets';

const initialState = {
  status: 'idle',
  error: null,
};

export const getTickets = createAsyncThunk(GET_TICKETS, async (data, { rejectWithValue }) => {
  try {
    const res = await LogisticService.postWithAuth(`${BASE_URL}/any/gerar_etiqueta`, data);

    if (res?.data?.fileBase) downloadBase64PDF(res.data.fileBase)
    return
  } catch (err) {
    return rejectWithValue(err.response.data || err.response);
  }
});

export const ticketSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    resetTicketSlice: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getTickets.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.status = 'fulfilled';
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'rejected';
      });
  },
});

export const {
  resetTicketSlice
} = ticketSlice.actions

export const ticketsSelector = (state) => state.ticketSlice;

export default ticketSlice.reducer;
