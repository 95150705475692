import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import pinsReducer from '../features/senninha/pinsSlice'
import hubsSelector from '../features/senninha/hubsSlice';
import angelsReducer from '../features/senninha/angelsSlice';
import routesManagementReducer from '../features/senninha/routesManagementSlice';
import trackingDeliveriesReducer from '../features/senninha/trackingDeliveriesSlice';
import optionsReducer from '../features/optionsSlice';
import ticketReducer from '../features/senninha/ticketSlice';
import riskAreasReducer from '../features/senninha/riskAreasSlice'
import oldSenninhaReducer from '../features/senninha/oldSenninhaSlice'

const rootPersistorConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: [] //If you don’t want to persist a part of your state you could put it in the blacklist. example => blacklist: [pinsSlice]
};

const rootReducer = combineReducers({
    pinsSlice: pinsReducer,
    hubsSlice: hubsSelector,
    angelsSlice: angelsReducer,
    routesManagementSlice: routesManagementReducer,
    trackingDeliveriesSlice: trackingDeliveriesReducer,
    optionsSlice: optionsReducer,
    ticketSlice: ticketReducer,
    riskAreasSlice: riskAreasReducer,
    oldSenninhaSlice: oldSenninhaReducer
});

const store = configureStore({
    reducer: persistReducer(rootPersistorConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);
export default store;
