import React, { useEffect, useMemo } from 'react';
import { FormSelect, FormTextArea, Drawer, Button } from '@stonelog/stonelog-design-system';
import { optionsSelector } from '../../../../../../../../../../features/optionsSlice';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { supplySchema } from './schemas/schemas';
import { usePreDeliverySimulationContext } from '../../../../../../../../../../hooks/usePreDeliverySimulationContext';

import * as S from '../styles';

const SupplyForm = ({
    os,
    infoOs,
    obsDefaultValue,
    isInMultiGroupForm,
    handleSaveClient
}) => {
    const {
        formsSimulationIdData,
        setFormsSimulationIdData,
        formsSimulationIdValitations,
        setFormsSimulationIdValidations
    } = usePreDeliverySimulationContext()

    const {
        supplies
    } = useSelector(optionsSelector);

    const initialFormData = useMemo(() => formsSimulationIdData[os]?.formData ?? {
        osInfo: infoOs,
        notes: obsDefaultValue,
        supllyKitAmount: 0
    }, [formsSimulationIdData, os, infoOs, obsDefaultValue]);

    const amountOptions = useMemo(() => (
        Array.from({ length: 500 }, (_, index) =>
          ({ value: index + 1, label: index + 1 }))
    ), [])
    
    const {
        control,
        formState: { isValid, isValidating, errors },
        getValues,
        trigger,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(supplySchema(isInMultiGroupForm)),
        defaultValues: initialFormData
    });

    const handleSave = () => {
        setFormsSimulationIdData((p) => ({
            ...p,
            [os]: {
                ...p[os],
                isSubmitted: true,
                formData: getValues(),
            }
        }))
        if (!isInMultiGroupForm) handleSaveClient()
    }

    const createOptions = (arrayStr) => 
        arrayStr.map((item) => ({ value: item, label: item }))

    useEffect(() => {
        trigger()
    }, [])

    useEffect(() => {
        if (!Object.keys(errors).length)
            setFormsSimulationIdValidations({
                ...formsSimulationIdValitations,
                [os]: {
                    ...formsSimulationIdValitations[os],
                    isValid,
                }
            })
    }, [isValidating, errors])

    return (
        <S.FormArea>
            <S.ClientFormBlock>
                <FormTextArea
                    control={control}
                    name="osInfo"
                    autoSize
                    label="Info da OS"
                    value="Cliente pediu para ser instalado a maquininha de modelo S920."
                    defaultValue={infoOs}
                    disabled
                />
                <S.SupplyFormSelects>
                    <FormSelect
                        control={control}
                        name="supllyKit"
                        label="Suprimento"
                        options={createOptions(supplies)}
                        placeholder="Selecione uma opção"
                        size="small"
                        style={{ width: '77%' }}
                    />
                    <FormSelect
                        control={control}
                        name="supllyKitAmount"
                        label="Qnt"
                        options={amountOptions}
                        placeholder="Selecione uma opção"
                        size="small"
                        style={{ width: '20%' }}
                    />            
                </S.SupplyFormSelects>
                {!isInMultiGroupForm && (
                    <FormTextArea
                        control={control}
                        name="notes"
                        label="Observações"
                        rows={3}
                        style={{ resize: 'none' }}
                    />
                )}
            </S.ClientFormBlock>

            {!isInMultiGroupForm &&
                <Drawer.Footer
                    style={{ margin: '0 -24px' }}
                >
                    <Button
                        type='primary'
                        width='100%'
                        height='52px'
                        size='large'
                        onClick={handleSave}
                        disabled={!isValid}
                    >
                        Salvar
                    </Button>
                </Drawer.Footer>
            }
        </S.FormArea>
    )
};

export default SupplyForm;
