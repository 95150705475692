import React, { useEffect, useState } from "react";
import { Alert, Button, Checkbox, Drawer, Input, Tag } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { postTrackingDeliveryPreBaixada, resetDeliveryPreBaixadaStates, trackingDeliveriesSelector } from "../../../../../../../features/senninha/trackingDeliveriesSlice";
import machineError from "@assets/imgs/machine-error.svg";
import { useSelector, useDispatch } from "react-redux";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";

import * as S from "./styles";


const FinishedContent = ({ onOpenDeliveryPreBaixadaDrawer }) => {
    const [searchText, setSearchText] = useState('')
    const [filteredDeliveries, setFilteredDeliveries] = useState([])
    const [checkedList, setCheckedList] = useState([])
    const [checkboxOptions, setCheckboxOptions] = useState([])

    const { 
        deliveriesPreBaixada, 
        statusGetDeliveriesPreBaixada, 
        errorGetDeliveriesPreBaixada,
        statusPostDeliveriesPreBaixada
    } = useSelector(trackingDeliveriesSelector)

    const dispatch = useDispatch();

    const loading = statusGetDeliveriesPreBaixada === 'pending'
    const checkAll = checkboxOptions.length === checkedList.length && checkedList.length
    const indeterminate = checkedList.length > 0 && checkedList.length < checkboxOptions.length

    const onCheckAllChange = (e) =>
        setCheckedList(e.target.checked ? checkboxOptions : [])   

    const handleChangeCheckbox = (checkedValue) =>
        setCheckedList((p) => checkedList.find((item) => item === checkedValue) ? 
            p.filter((item) => item !== checkedValue)
            : [...p, checkedValue]
        )

    const handleOssBaixa = () => {
        const payload = deliveriesPreBaixada.reduce((res, item) => {
            if (checkedList.includes(item.reference_key)) {
                return [...res, ...item.oss.map((item2) => ({
                    id_os: item2.id_os,
                    servico: item2.servico,
                    terminal_entrada: item2.terminal_entrada,
                    // terminal_saida: item2.terminal_saida
                }))]
            }
            return res
        } , [])

        const refIds = deliveriesPreBaixada.map((item) => item.reference_key)
        dispatch(postTrackingDeliveryPreBaixada({ refIds, payload }))
    }

    useEffect(() => {
        if (filteredDeliveries?.length) 
            setCheckboxOptions(filteredDeliveries.map(({ reference_key }) => reference_key))
    }, [filteredDeliveries])

    useEffect(() => {
        if (searchText) {
            const res = deliveriesPreBaixada?.filter(({ id_atendimento, oss }) => 
                (id_atendimento.includes(searchText)) 
                || (oss.find(({ id_os }) => id_os.toString().includes(searchText)))
            )
            setFilteredDeliveries(res)
        } else setFilteredDeliveries(deliveriesPreBaixada)
    }, [deliveriesPreBaixada, searchText])

    return (
        <Drawer.Body>
            {loading ? 
                <Drawer.Loading
                    message="Carregando..."
                />
                :
                errorGetDeliveriesPreBaixada ?
                    <Drawer.Response
                        element={ <img src={machineError} />}
                        message="Houve um erro!"
                    />
                    :
                    deliveriesPreBaixada?.length ?
                        <>
                            <S.AllDeliveryDrawerContent>
                                <S.HeaderDelivery>
                                    <Input
                                        label='Pesquisar'
                                        placeholder='Stonecode ou Nº da OS'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onPressEnter={(e) => {
                                            setActiveFilters((p) => ({ ...p, searchBar: e.target.value }))
                                        }}
                                        size="small"
                                        suffixIcon={<GingaIcon name='search' color='#767F8D' size='18px' />}
                                        allowClear
                                    />
                                </S.HeaderDelivery>
                                <S.DeliveryTopContent>
                                    <Checkbox
                                        label={`Selecionar todos`}
                                        indeterminate={indeterminate}
                                        onChange={onCheckAllChange}
                                        checked={checkAll}
                                        size={24}
                                    />
                                    <Button
                                        type='primary'
                                        height='36px'
                                        onClick={handleOssBaixa}
                                        loading={statusPostDeliveriesPreBaixada === 'pending'}
                                        disabled={!checkedList.length}
                                    >
                                        Dar baixa na OS ({checkedList.length})
                                    </Button>
                                </S.DeliveryTopContent>

                            </S.AllDeliveryDrawerContent>
                        
                            <S.AlertArea>
                                <Alert
                                    description="OSs que não forem baixadas dentro de 24h, serão baixadas automaticamente."
                                    type="warning"
                                />
                            </S.AlertArea>

                            {filteredDeliveries?.map((delivery, i) => (
                                    <Drawer.Row
                                        key={i}
                                        onClick={() => {
                                            onOpenDeliveryPreBaixadaDrawer(delivery)
                                            dispatch(resetDeliveryPreBaixadaStates())
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Drawer.RowPrefix>
                                            <Checkbox
                                                size={24}
                                                value={delivery.reference_key}
                                                checked={checkedList.includes(delivery.reference_key)}
                                                onChange={(e) => handleChangeCheckbox(e.target.value)}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </Drawer.RowPrefix>
                                        <Drawer.RowText
                                            text={capitalizeAllFirstLetters(delivery.cliente)}
                                            description={`${
                                                capitalizeAllFirstLetters(delivery.operador_logistico)
                                            } | ${
                                                delivery.oss.map(({ servico, id_os }) => 
                                                ` ${capitalizeAllFirstLetters(servico)} (OS ${id_os})`)
                                            } | Lote ${delivery.lote}`}
                                        />
            
                                        <Drawer.RowActions>
                                            <Tag
                                                text={delivery.horario_finalizado}
                                                type='default'
                                            />
                                        </Drawer.RowActions>
                                    </Drawer.Row>
                            ))}
                        </>
                    :
                    <Drawer.Empty />
            }
        </Drawer.Body>
    )
}

export default FinishedContent;
