import styled from "styled-components";


export const LeftContainer = styled.div`
  height: calc(100% - 48px);
  width: 68px;
  background-color: white;
  padding: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 12;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.19), 0px 6px 12px -2px rgba(0, 0, 0, 0.04); 
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HasDelivery = styled.div`
  background-color: rgba(247, 62, 65, 1);
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 99px;
  right: 3px;
  bottom: 3px;
`;