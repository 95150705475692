import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InstallationForm from './InstallationForm';
import UninstallationForm from './UninstallationForm';
import MaintenanceForm from './MaintenanceForm';
import ExchangeForm from './ExchangeForm';
import EventForm from './EventForm';
import SupplyForm from './SupplyForm';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import { Button, Drawer, Tag, TextArea } from '@stonelog/stonelog-design-system';
import { capitalizeMultipleFirstLetters } from '../../../../../../../../../../utils/formatter';
import { usePreDeliverySimulationContext } from '../../../../../../../../../../hooks/usePreDeliverySimulationContext';

import * as S from '../styles';


const MultiGroupForm = ({ attendancesData, handleSaveClient }) => {
    const [notes, setNotes] = useState()
    const [showOs, setShowOs] = useState()

    const {
        formsSimulationIdData,
        setFormsSimulationIdData
    } = usePreDeliverySimulationContext()

    const getFormattedNotes = useMemo(() => {
        const add = ['INSTALAÇÃO', 'MANUTENÇÃO', 'TROCA', 'EVENTO']
        const remove = ['DESINSTALAÇÃO', 'MANUTENÇÃO', 'TROCA', 'EVENTO']
        let addAmount = 0
        let removeAmount = 0
        let eventsAmount = 0 
        
        attendancesData?.forEach(({ groupService }) => {
            if(add.includes(groupService)) addAmount += 1
            if(remove.includes(groupService)) removeAmount += 1
            if(groupService === 'ENVIO DE SUPRIMENTOS') eventsAmount += 1
        })
        
        return `${addAmount > 0 ? `Entregar ${addAmount} máquinas ` : ''}${addAmount > 0 && removeAmount > 0 ? `e ` : ''}${removeAmount > 0 ? `Coletar ${removeAmount} máquinas` : ''}${eventsAmount > 0 ? `; Entregar ${eventsAmount} Kits` : ''}`
    }, [])
            
    const {
        formsSimulationIdValitations,
    } = usePreDeliverySimulationContext()

    const getForm = useCallback((props, i) => {
        const { formsSimulationIdValitations } = usePreDeliverySimulationContext()
        
        const isCurrentFormValid = formsSimulationIdValitations[props.os].isValid
        const forms = {
            'INSTALAÇÃO': <InstallationForm {...props} />,
            'DESINSTALAÇÃO': <UninstallationForm {...props} />,
            'MANUTENÇÃO': <MaintenanceForm {...props} />,
            'TROCA': <ExchangeForm {...props} />,
            'EVENTO': <EventForm {...props} />,
            'SUPRIMENTOS': <SupplyForm {...props} />,
        }
                
        const isSameOs = showOs === props.os
        const handleToggle = () => {
            if (isSameOs) setShowOs()
            else setShowOs(props.os)
        }
      
        return (
            <div key={i} style={{ marginTop: 16 }}>
                <S.GroupedServiceRow>
                    <div>
                        <div>{props.os}</div>
                        <div>{capitalizeMultipleFirstLetters(props.groupService)}</div>
                    </div>

                    <div>
                        <Tag
                            text={isCurrentFormValid ? 'Preenchido' : 'Pendente'}
                            type={isCurrentFormValid ? 'success' : 'default'}
                        />
                        <GingaIcon
                            name="chevron-down"
                            color="#A6AEBA"
                            size={20}
                            onClick={handleToggle}
                            style={{
                                cursor: 'pointer',
                                transition: "all 0.5s ease",
                                transform: `rotate(${isSameOs ? "0.5turn" : "1turn"})`,
                            }}
                        />
                    </div>
                </S.GroupedServiceRow>

                {isSameOs && forms[props.groupService]}                
            </div>
        )
    }, [showOs])

    const checkAllFormsAreValid = () => attendancesData.every(({ os }) => (
        formsSimulationIdValitations[os].isValid
    )) && notes

    const handleSave = () => {
        setShowOs()
        handleSaveClient()

        setFormsSimulationIdData((p) => {
            let aux = p
            attendancesData.forEach((item) => {
                if (aux[item.os]){
                    aux = { 
                        ...aux, 
                        [item.os]: {
                            ...aux[item.os],
                            formData: {
                                ...aux[item.os].formData,
                                notes
                            }
                        } 
                    }
                }
            })

            return aux
        })
    }

    useEffect(() => {
        const osData = attendancesData.find(({ os }) => formsSimulationIdData[os])
        const notesAux = osData?.os ? formsSimulationIdData[osData.os]?.formData?.notes : ''

        if (notesAux) {
            setNotes(notesAux)
        } else if (!notes) setNotes(getFormattedNotes)
    }, [])

    return (
        <S.MultiFormContainer>
            <div>
                {attendancesData.map((item, i) => (
                    getForm(item, i)
                ))}
                <TextArea
                    name="notes"
                    label="Observações"
                    rows={3}
                    value={notes}
                    onChange={(e) => {setNotes(e.target.value)}}
                    style={{ resize: 'none' }}
                />
            </div>

            <Drawer.Footer
                style={{ margin: '0 -24px' }}
            >
                <Button
                    type='primary'
                    width='100%'
                    height='52px'
                    size='large'
                    disabled={!checkAllFormsAreValid()}
                    onClick={handleSave}
                >
                    Salvar
                </Button>
            </Drawer.Footer>
        </S.MultiFormContainer>
    )
};

export default MultiGroupForm;
