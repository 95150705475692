import React, { useEffect, useMemo } from 'react';
import { FormInput, FormSelect, FormTextArea, Drawer, Button } from '@stonelog/stonelog-design-system';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import { usePreDeliverySimulationContext } from '../../../../../../../../../../hooks/usePreDeliverySimulationContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventSchema } from './schemas/schemas';
import { useSelector } from 'react-redux';
import { optionsSelector } from '../../../../../../../../../../features/optionsSlice';

import * as S from '../styles';


const EventForm = ({
    os,
    infoOs,
    obsDefaultValue,
    outputSerial,
    inputSerial,
    posRecommendation,
    isInMultiGroupForm,
    handleSaveClient
}) => {
    const {
        formsSimulationIdData,
        setFormsSimulationIdData,
        formsSimulationIdValitations,
        setFormsSimulationIdValidations
    } = usePreDeliverySimulationContext()

    const {
        defects,
        solutions
    } = useSelector(optionsSelector);

    const initialFormData = useMemo(() => formsSimulationIdData[os]?.formData ?? {
        osInfo: infoOs,
        inputSerial,
        outputSerial,
        // defects,
        // solutions,
        notes: obsDefaultValue
    }, [formsSimulationIdData, os, infoOs, inputSerial, outputSerial, obsDefaultValue]);

    const {
        control,
        formState: { isValid, isValidating, errors },
        getValues,
        trigger,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(eventSchema(isInMultiGroupForm)),
        defaultValues: initialFormData
    });

    const handleSave = () => {
        setFormsSimulationIdData((p) => ({
            ...p,
            [os]: {
                ...p[os],
                isSubmitted: true,
                formData: getValues(),
            }
        }))
        if (!isInMultiGroupForm) handleSaveClient()
    }

    const createOptions = (arrayStr) => 
        arrayStr.map((item) => ({ value: item, label: item }))

    useEffect(() => {
        trigger()
    }, [])

    useEffect(() => {
        if (!Object.keys(errors).length)
            setFormsSimulationIdValidations({
                ...formsSimulationIdValitations,
                [os]: {
                    ...formsSimulationIdValitations[os],
                    isValid,
                }
            })
    }, [isValidating, errors])

  return (
    <S.FormArea>
        <S.ClientFormBlock>
            <FormTextArea
                control={control}
                name="osInfo"
                autoSize
                label="Info da OS"
                disabled
            />
            <FormInput
                control={control}
                name="outputSerial"
                label="Serial de saída"
                placeholder="Bipe ou digite aqui"
                disabled
                size="small"
            />
            <FormInput
                control={control}
                name="inputSerial"
                label="Serial de entrada"
                placeholder="Digite ou bipe aqui"
                suffixIcon={<GingaIcon color="#767F8D" name="code-bar-scanner" size={24}/>}
                supportText={posRecommendation}
                size="small"
            />
            <FormSelect
                control={control}
                name="defects"
                label="Defeito"
                options={createOptions(defects)}
                placeholder="Selecione uma opção"
                size="small"
            />
            <FormSelect
                control={control}
                name="solutions"
                label="Solução"
                options={createOptions(solutions)}
                placeholder="Selecione uma opção"
                size="small"
            />
            {!isInMultiGroupForm && (
                <FormTextArea
                    control={control}
                    name="notes"
                    label="Observações"
                    rows={3}
                    style={{ resize: 'none' }}
                />
            )}
        </S.ClientFormBlock>

        {!isInMultiGroupForm &&
            <Drawer.Footer
                style={{ margin: '0 -24px' }}
            >
                <Button
                    type='primary'
                    width='100%'
                    height='52px'
                    size='large'
                    onClick={handleSave}
                    disabled={!isValid}
                >
                    Salvar
                </Button>
            </Drawer.Footer>
        }
    </S.FormArea>
  )
};

export default EventForm;
