import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Drawer, Radio, RadioGroup, Tag, TextArea } from '@stonelog/stonelog-design-system';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import { addSelectedPreDeliveryPins, deleteSimulationData, getBalance, postRequestPreDeliveries, postSimulatePreDeliveries, resetRequestPreDeliveriesStatus, resetSelectedPreDeliveryPins, resetSimulatePreDeliveriesStatus, routesManagementSelector, setFlagOpenTrackDeliveries } from '../../../../../../../../../features/senninha/routesManagementSlice';
import Paginator from '../../../../../../../../../components/Paginator';
import UninstallationForm from './ServiceGroupForms/UninstallationForm';
import InstallationForm from './ServiceGroupForms/InstallationForm';
import MaintenanceForm from './ServiceGroupForms/MaintenanceForm';
import ExchangeForm from './ServiceGroupForms/ExchangeForm';
import EventForm from './ServiceGroupForms/EventForm';
import SupplyForm from './ServiceGroupForms/SupplyForm';
import MultiGroupForm from './ServiceGroupForms/MultiGroupForm';
import { capitalizeAllFirstLetters } from "../../../../../../../../../consumingApi/services/helper";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import AddNewPins from './AddNewPins';
import { setMapWorkflow } from '../../../../../../../../../features/senninha/pinsSlice';
import { usePreDeliverySimulationContext } from '../../../../../../../../../hooks/usePreDeliverySimulationContext'
import { eventSchema, exchangeSchema, installationSchema, maintenanceSchema, uninstallationSchema } from './ServiceGroupForms/schemas/schemas';
import machineSuccess from "@assets/imgs/machine-success.svg";
import { hubsSelector } from '../../../../../../../../../features/senninha/hubsSlice';
import { formatArrayToCommaString } from '../../../../../../../../../utils/formatter';
import { getTrackingDeliveries } from '../../../../../../../../../features/senninha/trackingDeliveriesSlice';

import * as S from './styles';


const Simulation = ({ onClose, setActiveKeyTab }) => {
    const [activeOption, setActiveOption] = useState('list')
    const [searchedValue, setSearchedValue] = useState('')
    const [checked, setChecked] = useState('Mais barato')
    const [currentPage, setCurrentPage] = useState(1)
    const [filteredSimulations, setFilteredSimulations] = useState([])

    const dispatch = useDispatch();

    const {
        // balanceData,
        selectedRelocatePins,
        simulationsData,
        requestedDeliveriesHashes,
        selectedPreDeliveryPins,
        statusRequestPreDeliveries
    } = useSelector(routesManagementSelector);

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const {
        formsSimulationIdData,
        setFormsSimulationIdData,
        formsSimulationIdValitations,
        setFormsSimulationIdValidations
    } = usePreDeliverySimulationContext();

    const totalPages = simulationsData?.simulacoes?.length
    const techs = useMemo(() => 
        selectedPreDeliveryPins?.flatMap(({ tecnologias }) => tecnologias)
    , [selectedPreDeliveryPins])
    const forms = {
        'INSTALAÇÃO': {
            form: InstallationForm,
            schema: installationSchema,
            notes: 'Entregar 1 máquina.'
        },
        'DESINSTALAÇÃO': {
            form: UninstallationForm,
            schema: uninstallationSchema,
            notes: 'Coletar 1 máquina.'
        },
        'MANUTENÇÃO': {
            form: MaintenanceForm,
            schema: maintenanceSchema,
            notes: 'Entregar 1 máquina e Coletar 1 máquina.'
        },
        'TROCA': {
            form: ExchangeForm,
            schema: exchangeSchema,
            notes: 'Entregar 1 máquina e Coletar 1 máquina.'
        },
        'EVENTO': {
            form: EventForm,
            schema: installationSchema,
            notes: 'Entregar 1 máquina e Coletar 1 máquina.'
        },
        'ENVIO DE SUPRIMENTOS': {
            form: SupplyForm,
            schema: eventSchema,
            notes: 'Entregar 1 KIT'
        },
        'MULTIGROUP': {
            form: MultiGroupForm,
            schema: null
        },
    }

    const FormRenderer = useCallback(() => {
        const servicesAmount = simulationsData?.simulacoes[currentPage-1]?.entregas?.length
        if (!servicesAmount || servicesAmount > 1) return <></>
        
        const FormComponent = forms[simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.servico].form;

        const handleSaveClient = () => {
            if (currentPage === totalPages) {
                setActiveOption('list')
            } else setCurrentPage((p) => p + 1)
        }
    
        return (
            <FormComponent
                os={simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.numero_os}
                infoOs={simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.info_os}
                inputSerial={simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.terminal_entrada}
                outputSerial={simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.terminal_saida}
                posRecommendation={simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.pos_recomendada}
                obsDefaultValue={forms[simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.servico].notes}
                handleSaveClient={handleSaveClient}
            />
        );
    }, [simulationsData, currentPage, formsSimulationIdData])

    const MultiGroupFormRenderer = useCallback(() => {
        const servicesAmount = simulationsData?.simulacoes[currentPage-1]?.entregas?.length
        if (!servicesAmount || servicesAmount === 1) return <></>

        const FormComponent = forms['MULTIGROUP'].form;

        const handleSaveClient = () => {
            if (currentPage === totalPages) {
                setActiveOption('list')
            } else setCurrentPage((p) => p + 1)
        }
    
        return (
            <FormComponent
                handleSaveClient={handleSaveClient}
                attendancesData={
                    simulationsData?.simulacoes[currentPage-1].entregas?.map((item) => ({
                        os: simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.numero_os,
                        groupService: item.servico,
                        inputSerial: item.terminal_entrada,
                        outputSerial: item.terminal_saida,
                        os: item.numero_os,
                        posRecommendation: item.pos_recomendada,
                        infoOs: item.info_os,
                        // obsDefaultValue: forms[item.servico].notes,
                        isInMultiGroupForm: true,
                    }))
                }
            />
        );
    }, [simulationsData, currentPage])

    const onChangeRadios = ({ target: { value } }) => {
        setChecked(value);
    };

    const getRadioOptions = () => {
        const opls = simulationsData?.operadores_logisticos.reduce((res, item) => {
            if (res[item.operador_logistico]) return ({...res, [item.operador_logistico]: item.valor + res[item.operador_logistico] || 0})
            else return ({ ...res, [item.operador_logistico]: item.valor })
        }, {})
        
        const cheapest = simulationsData?.mais_barato.reduce((res, item) => {
            if (res['Mais barato']) return ({...res, 'Mais barato': item.valor + res['Mais barato'] || 0})
            else return ({ ...res, 'Mais barato': item.valor })
        }, {})
        
        return Object.entries({ ...opls, ...cheapest }).map((item) => ({
            label: `${item[0]}-${item[1]}`,
            value: item[0],
        }))
    }

    const countItems = (items) => {
        const counts = items.reduce((data, item) => {
            data[item] = (data[item] || 0) + 1;
            return data;
        }, {});

        return Object.keys(counts).map((key) => ({
            name: key,
            amount: counts[key]
        }));
    }

    const getServicesFormatted = (deliveries) => {
        const aux = deliveries?.map(({ servico }) => servico)
        if (!aux) return ''

        return aux.length === 1 ?
            capitalizeAllFirstLetters(aux[0])
            : countItems(aux).map(({ name, amount }) => 
                `${capitalizeAllFirstLetters(name)} (${amount})`
              ).toString().replace(',', ', ')
    }

    const getOSsFormatted = (deliveries) => {
        const aux = deliveries?.map(({ numero_os }) => numero_os)
        if (!aux) return ''

        return aux.length === 1 ?
            aux[0]
            : aux.toString().replaceAll(',', ', ')
    }

    const getPriceFormatted = (id_simulacao) => 
        simulationsData?.mais_barato.find((item) => item.id_simulacao === id_simulacao)?.valor || 0

    const handleListRowClick = (idSimulacao) => {
        const simulationIdx = simulationsData?.simulacoes.findIndex(({ id_simulacao }) => idSimulacao === id_simulacao)

        if (simulationIdx !== -1){
            setCurrentPage(simulationIdx+1)
            setActiveOption('client')
        }
    }

    const transformDataPerGroup = () => {
        const groupedData = {};
    
        Object.entries(formsSimulationIdData).forEach(([idOs, data]) => {
            const multiGroup = data.multiGroup !== null ? data.multiGroup : idOs;
            const transformedData = {
                ...data,
                formData: {
                    ...data.formData,
                    idOs,
                }
            };
    
            if (!groupedData[multiGroup]) {
                groupedData[multiGroup] = [];
            }
            
            groupedData[multiGroup].push(transformedData);
        });
    
        return Object.values(groupedData);
    }

    const handleRequestDeliveries = () => {
        const payload = transformDataPerGroup().map((item) => {
            let oplData
            if (checked === 'Mais barato') 
                oplData = simulationsData.mais_barato.find((item2) => item2.reference_key ===  item[0].reference_key)
            else oplData = simulationsData.operadores_logisticos.find((item2) => 
                item2.reference_key ===  item[0].reference_key && item2.operador_logistico === checked
            )

            return {
                quoteId: oplData.id_simulacao,
                referenceKey: oplData.reference_key,
                idPolo: selectedHubInfos.value,
                oss: item.map(({ formData, service }) => ({
                    idOs: formData.idOs,
                    servico: service,
                    solucao: formData.solutions,
                    defeito: formData.defects,
                    suprimento: formData.supllyKit,
                    quantidade: formData.supllyKitAmount,
                    terminal_entrada: formData.inputSerial,
                    // info_os: formData.osInfo,
                    // terminal_saida: formData.outputSerial,
                })),
                observacao: item[0].formData.notes,
                carrier: {
                    name: checked,
                    service: "normal"
                },
              deliveryAddress: oplData.endereco_entrega
            }
        })

        dispatch(postRequestPreDeliveries(payload))
    }

    const handleDisabledAllDeliverysBtn = () => {
        const deliveries = simulationsData.simulacoes?.flatMap(({ entregas }) => entregas)

        if (!deliveries.length || !formsSimulationIdValitations || !formsSimulationIdData || !checked)
            return true

        return !deliveries.every(({ numero_os }) => 
            formsSimulationIdValitations[numero_os]?.isValid 
            && (deliveries.length > 1 || formsSimulationIdData[numero_os]?.isSubmitted)
        )
    }
    console.log('context Data', formsSimulationIdData, formsSimulationIdValitations) 
    
    const handleAddNewPreDeliveryPins = () => {
        const payload = (selectedRelocatePins).concat(selectedPreDeliveryPins).map((item) => ({
            "oss": item.oss.map(({ id_os, servico }) => ({ idOs: id_os.toString(), servico })),
            "idPolo": selectedHubInfos.value,
            "deliveryAddress": {
                "nick": item.cliente,
                "zipCode": item.cep,
                "city": item.oss[0].cidade,
                "neighborhood": item.oss[0].referencia,
                "addressNumber": item.numero_endereco,
                "address": item.endereco,
                "countryState": item.uf,
                "latitude": item.lat,
                "longitude": item.lng,
                "workingHours": item.oss[0].horario_funcionamento || null,
                "reference": null,
                "complement": item.complemento,
                "country": "BRASIL"
            }
        }))
        dispatch(addSelectedPreDeliveryPins(selectedRelocatePins))
        dispatch(postSimulatePreDeliveries(payload))
    }

    const getCheckValidFormIcon = (deliveries) => {
        const isOsReady = deliveries?.every(({ numero_os }) => 
            formsSimulationIdValitations[numero_os]?.isValid 
            && (deliveries?.length > 1 || formsSimulationIdData[numero_os]?.isSubmitted)
        )

        return (
            isOsReady ? 
                <GingaIcon name="solid-check-round" color="#00A868" size={20} />
                : <GingaIcon name="round-none" color="#A6AEBA" size={20} /> 
        )
    }

    // useEffect(() => {
        // dispatch(getBalance()) // so na v3 
    // }, [])

    useEffect(() => {
        if (simulationsData?.simulacoes.length && searchedValue) {
            const searchedValueAux = searchedValue.toLowerCase()
    
            setFilteredSimulations(simulationsData.simulacoes.filter((item) => 
                item.stonecode.toLowerCase().includes(searchedValueAux)
                || item.cliente.toLowerCase().includes(searchedValueAux)
                || item.entregas?.find((item2) => item2.numero_os.toString().includes(searchedValueAux))
            ))
        }
        if (!searchedValue) setFilteredSimulations(simulationsData?.simulacoes || []);
    }, [simulationsData, searchedValue])

    useEffect(() => {
        if (activeOption === 'addNewPins') 
            dispatch(setMapWorkflow('routesDrawer-relocation'))
        else dispatch(setMapWorkflow('routesDrawer'))
    }, [activeOption])

    useEffect(() => {
        if (!formsSimulationIdData && simulationsData?.simulacoes?.length > 0) {
            let dataAux = {}
            let validationsAux = {}
            simulationsData.simulacoes.forEach(({ id_simulacao, reference_key, entregas }, i) => {
                entregas?.forEach(({ numero_os, servico }) => {            
                    if (!dataAux[numero_os]) {
                        dataAux = { 
                            ...dataAux, 
                            [numero_os]: {
                                id_simulacao,
                                reference_key,
                                formData: null,
                                service: servico,
                                isSubmitted: false,
                                multiGroup: entregas.length > 1 ? i : null
                            }
                        }
                        validationsAux = { 
                            ...validationsAux, 
                            [numero_os]: {
                                isValid: undefined,
                            }
                        }
                    }
                })
            })
            setFormsSimulationIdData(dataAux)            
            setFormsSimulationIdValidations(validationsAux)
        }
    }, [simulationsData?.simulacoes])

    useEffect(() => {
        if (statusRequestPreDeliveries === 'pending')
            setActiveOption('loadingRequestDeliveries')
        if (statusRequestPreDeliveries === 'fulfilled') {
            dispatch(resetRequestPreDeliveriesStatus())
            setActiveOption('successRequestDeliveries')
        }
    }, [statusRequestPreDeliveries])

    const drawerHeaderContent = {
        list: 
            <></>,
            // <S.BalanceArea>
            //     {balanceData && 
            //         <>
            //             <Tag
            //                 iconName='transport-motorcycle'
            //                 text={`${balanceData.balance > 0 ? '' : '-'}R$ ${balanceData.balance.toString()}`}
            //                 type={balanceData?.balance > 0 ? 'success' : 'error'}
            //             />
            //             <p>{balanceData.dailyLimit}</p>
            //         </>
            //     }
            // </S.BalanceArea>,
        client:                
            <Paginator
                totalPages={totalPages}
                currentPage={currentPage}
                onChange={setCurrentPage}
            />,
        addNewPins: null,
        loadingRequestDeliveries: null,
        successRequestDeliveries: null,
    }

    const drawerBodyContent = {
        list: 
            <>
                <div>
                    <Drawer.SearchBar
                        label="Pesquisar"
                        placeholder="Nome do cliente, SC, Nº da OS"
                        setSearchedValue={setSearchedValue}
                        actionButton={
                            <Button
                                type="primary"
                                width="44px"
                                onClick={() => setActiveOption('addNewPins')}
                            >
                                <GingaIcon name="round-add" color="#fff" size={20} />
                            </Button>
                        }
                    />
                </div>

                <S.Tags>
                    {countItems(techs).map(({ name, amount }) => (
                        <Tag
                            iconName='device-card-machine'
                            text={`${capitalizeAllFirstLetters(name)} (${amount})`}
                        />
                    ))}
                </S.Tags>

                <S.Radios>
                    <RadioGroup value={checked} onChange={onChangeRadios}>
                        {getRadioOptions().map(({ label, value }, i) => (
                            <Radio
                                key={i}
                                label={label.split('-')[0]}
                                value={value}
                                supportLabel={`R$${parseFloat(label.split('-')[1]).toFixed(2)}`.replace('.', ',')}
                            />
                        ))}
                    </RadioGroup>
                </S.Radios>
            
                <div>
                    {filteredSimulations.length && filteredSimulations.map((item) => (
                        <Drawer.Row
                            onClick={() => handleListRowClick(item.id_simulacao)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Drawer.RowText
                                text={item.cliente}
                                description={`${
                                    item.operador_logistico
                                } | ${
                                    getServicesFormatted(item.entregas).toString().replaceAll(',', ', ')
                                } | OS ${
                                    getOSsFormatted(item.entregas)}`
                                }
                            />

                            <Drawer.RowActions>
                                {checked === 'Mais barato' &&
                                    <Tag text={`R$${parseFloat(getPriceFormatted(item.id_simulacao)).toFixed(2)}`}/>
                                }

                                <GingaIcon name="trash" color="#E6171E" size={20} onClick={(e) => {
                                    e.stopPropagation()
                                    dispatch(deleteSimulationData(item.id_simulacao))
                                }} />

                                {getCheckValidFormIcon(item.entregas)}
                            </Drawer.RowActions>
                        </Drawer.Row>
                    ))}
                </div>
            </>,
        client:                
            <S.ClientArea>
                {simulationsData && simulationsData.simulacoes?.length &&
                    <>
                        <div>
                            <S.ClientInfoBlock>
                                <S.Row>
                                    <span>Polo</span>
                                    <span>{capitalizeAllFirstLetters(simulationsData?.endereco_polo)}</span>
                                </S.Row>
                                <S.Row>
                                    <span>Contato (Distrital) <GingaIcon name="round-info" color="#A6AEBA" size={20}/></span>
                                    <span>{simulationsData?.contato_distrital}</span>
                                </S.Row>
                            </S.ClientInfoBlock>
                            <S.ClientInfoBlock>
                                <S.Row>
                                    <span>Cliente</span>
                                    <span>{capitalizeAllFirstLetters(simulationsData?.simulacoes[currentPage-1]?.cliente)}</span>
                                </S.Row>
                                <S.Row>
                                    <span>Endereço</span>
                                    <span>{capitalizeAllFirstLetters(simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.endereco)}</span>
                                </S.Row>
                                <S.Row>
                                    <span>Previsão de entrega</span>
                                    <span>
                                        <Tag
                                            text={simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.previsao_entrega}
                                        />
                                    </span>
                                </S.Row>
                                <S.Row>
                                    <span>Contratante</span>
                                    <span>{capitalizeAllFirstLetters(simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.contratante)}</span>
                                </S.Row>
                                <S.Row>
                                    <span>Stonecode</span>
                                    <span>{simulationsData?.simulacoes[currentPage-1]?.entregas?.[0]?.id_atendimento}</span>
                                </S.Row>
                                <S.Row>
                                    <span>Grupo de serviço</span>
                                    <span>{getServicesFormatted(simulationsData?.simulacoes[currentPage-1]?.entregas)}</span>
                                </S.Row>
                                <S.Row>
                                    <span>Nº da OS</span>
                                    <span>{getOSsFormatted(simulationsData?.simulacoes[currentPage-1]?.entregas)}</span>
                                </S.Row>
                                <S.Row>
                                    <span>Operador logístico</span>
                                    <span>{simulationsData?.simulacoes[currentPage-1]?.operador_logistico}</span>
                                </S.Row>
                            </S.ClientInfoBlock>
                        </div>

                        <FormRenderer />
                        <MultiGroupFormRenderer />
                    </>
                }
            </S.ClientArea>,
        addNewPins: <AddNewPins />,
        loadingRequestDeliveries: 
            <Drawer.Loading
                message="Aguarde um momento, estamos solicitando as deliveries..."
            />,
        successRequestDeliveries: 
            <Drawer.Response
                element={<img src={machineSuccess} />}
                message={
                    `Solicitações realizadas com sucesso! Lote${
                        requestedDeliveriesHashes?.length > 1 ? 's' : ''
                    } nº ${formatArrayToCommaString(requestedDeliveriesHashes)}.`
                }
            />,
    }

    const drawerFooterContent = {
        list: 
            <S.BalanceArea>
                <>
                    <Button
                        type='primary'
                        width='100%'
                        height='52px'
                        size='large'
                        disabled={handleDisabledAllDeliverysBtn()}
                        onClick={handleRequestDeliveries}
                    >
                        Solicitar deliveries ({simulationsData?.simulacoes?.length})
                    </Button>

                    <Button
                        width='100%'
                        height='52px'
                        size='large'
                        onClick={() => {
                            setActiveKeyTab('1')
                            dispatch(resetSelectedPreDeliveryPins())
                            dispatch(resetSimulatePreDeliveriesStatus())
                        }}
                    >
                        Cancelar
                    </Button>
                </>
            </S.BalanceArea>,
        client: null,
        addNewPins: 
            <>
                <Button
                    type='primary'
                    width='100%'
                    height='52px'
                    size='large'
                    onClick={handleAddNewPreDeliveryPins}
                >
                    Adicionar na lista
                </Button>

                <Button
                    width='100%'
                    height='52px'
                    size='large'
                    onClick={() => {
                        setActiveOption('list')
                    }}
                >
                    Cancelar
                </Button>
            </>,
        loadingRequestDeliveries: null,
        successRequestDeliveries: 
            <>
                <Button
                    type='primary'
                    width='100%'
                    height='52px'
                    size='large'
                    onClick={() => {
                        dispatch(resetRequestPreDeliveriesStatus())
                        onClose()
                        dispatch(setFlagOpenTrackDeliveries(true))
                        dispatch(getTrackingDeliveries(selectedHubInfos.children))
                    }}
                >
                    Acompanhar deliveries
                </Button>

                <Button
                    width='100%'
                    height='52px'
                    size='large'
                    onClick={() => {
                        dispatch(resetRequestPreDeliveriesStatus())
                        onClose()
                        // TODO: verif states do slice q preciso resetar
                    }}
                >
                    Fechar
                </Button>
            </>,
    }

    return (
        <>
            <Drawer.Body>
                {!!drawerHeaderContent[activeOption] && 
                    <S.Header>
                            <div>
                                <S.Button
                                    onClick={() => setActiveOption('list')}
                                    isActive={activeOption === 'list'}
                                >
                                    Por lista
                                </S.Button>
                                <S.Button
                                    onClick={() => setActiveOption('client')}
                                    isActive={activeOption === 'client'}
                                >
                                    Por cliente
                                </S.Button>
                            </div>

                        {drawerHeaderContent[activeOption]}
                    </S.Header>
                }
            
                {drawerBodyContent[activeOption]}
            </Drawer.Body>

            {!!drawerFooterContent[activeOption] && 
                <Drawer.Footer>
                    {drawerFooterContent[activeOption]}
                </Drawer.Footer>
            }
        </>
    )
};

export default Simulation;
