import React, { useEffect } from "react";
import { Button, Drawer, Tag } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { deleteTrackingDelivery, resetDeleteDeliveryStates, trackingDeliveriesSelector } from "../../../../../../../features/senninha/trackingDeliveriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";

import * as S from "./styles";


const DetailsDeliveryContent = ({ open, onClose, data }) => {
    const {
        statusDeleteDelivery,
    } = useSelector(trackingDeliveriesSelector)

    const dispatch = useDispatch()

    const countItems = (items) => {
        const counts = items.reduce((data, item) => {
            data[item] = (data[item] || 0) + 1;
            return data;
        }, {});

        return Object.keys(counts).map((key) => ({
            name: key,
            amount: counts[key]
        }));
    }

    const getServicesFormatted = (services) => {
        const aux = services.map(({ servico }) => servico)
        if (!aux) return ''

        return aux.length === 1 ?
            capitalizeAllFirstLetters(aux[0])
            : countItems(aux).map(({ name, amount }) => 
                `${capitalizeAllFirstLetters(name)} (${amount})`
              ).toString().replace(',', ', ')
    }

    const getOSsFormatted = (oss) => {
        const aux = oss.map(({ id_os }) => id_os)
        if (!aux) return ''

        return aux.length === 1 ?
            aux[0]
            : aux.toString().replaceAll(',', ', ')
    }

    const handleDeleteDelivery = () => {
        dispatch(deleteTrackingDelivery(data.id_simulacao))
    }

    useEffect(() => {
        if (statusDeleteDelivery === 'fulfilled') {
            onClose()
            dispatch(resetDeleteDeliveryStates())
        }
    }, [statusDeleteDelivery])

    return (
        <>
            <Drawer.Body>
                {data &&
                    <S.AllDeliveryDrawerContent>
                        <S.AllBodyContainer>
                            <S.BodyTopContainer>
                                <S.BodyTopContent>
                                    <p>
                                        Status
                                    </p>
                                    <span>
                                        <Tag
                                            text={capitalizeAllFirstLetters(data.status.mensagem)}
                                            type={data.status.tipo}
                                        />
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Previsão de entrega
                                    </p>
                                    <span>
                                        {data.previsao_entrega}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Cliente
                                    </p>
                                    <span>
                                        {data.cliente}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Contato
                                    </p>
                                    <span>
                                        {data.contato}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Endereço
                                    </p>
                                    <span>
                                        {data.endereco}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Contratante
                                    </p>
                                    <span>
                                        {data.contratante}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Stonecode
                                    </p>
                                    <span>
                                        {data.id_atendimento}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Grupo de serviço
                                    </p>
                                    <span>
                                        {getServicesFormatted(data.oss)}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Nº da OS
                                    </p>
                                    <span>
                                        {getOSsFormatted(data.oss)}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Operador logístico
                                    </p>
                                    <span>
                                        {capitalizeAllFirstLetters(data.operador_logistico)}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Código Any
                                    </p>
                                    <span>
                                        {data.tracking_code}
                                    </span>
                                </S.BodyTopContent>
                            </S.BodyTopContainer>
                        </S.AllBodyContainer>
                    </S.AllDeliveryDrawerContent>
                }
            </Drawer.Body>
    
            {data &&
                <Drawer.Footer>
                    <Button
                        width="100%"
                        type="negative"
                        size="medium"
                        onClick={handleDeleteDelivery}
                        loading={statusDeleteDelivery === 'pending'}
                    >
                        <GingaIcon name='round-error' color="#FFFFFF" size={20} />
                        Cancelar delivery
                    </Button>
                </Drawer.Footer>
            }
        </>
    )
}

export default DetailsDeliveryContent;
